// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.version-display {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 1);
    color: rgba(0, 0, 0, 0.45);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    z-index: 100;
}`, "",{"version":3,"sources":["webpack://./src/components/versionDisplay/versionDisplay.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,wCAAwC;IACxC,0BAA0B;IAC1B,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,YAAY;AAChB","sourcesContent":[".version-display {\n    position: absolute;\n    bottom: 10px;\n    right: 10px;\n    background-color: rgba(255, 255, 255, 1);\n    color: rgba(0, 0, 0, 0.45);\n    padding: 5px 10px;\n    border-radius: 5px;\n    font-size: 14px;\n    z-index: 100;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
