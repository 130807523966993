import { $host } from "../http";

class ActionLogService {
  async createEventLog(body) {
    return $host.post("/api/event", body);
  }

  async removeEventLog(id) {
    return $host.delete(`/api/event/${id}`);
  }
  async editEventLog(eventLogData) {
    return $host.put("/api/event", eventLogData);
  }
}

export default new ActionLogService();
