import Calendar from "@fullcalendar/react";
import { makeAutoObservable } from "mobx";

export default class CalendarStore {
  /** @type {Calendar | null} */
  calendar = null;
  update = null;

  constructor() {
    makeAutoObservable(this);
  }

  setCalendar(calendar) {
    this.calendar = calendar;
  }

  resize() {
    this.calendar?.requestResize();
  }

  async updateList() {
    this.update();
  }
}
