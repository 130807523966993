import { Select } from "antd";
import React, { useEffect, useState } from "react";

import notificationService from "../../services/notificationService";
import { notificationWarehouse } from "../../utils/helpers";
import { requestWrapper } from "../../utils/requestWrapper";

const { Option } = Select;

const SelectStatusEvent = ({ value, onChange, showSearch = true }) => {
  const [statusList, setStatusList] = useState([]);

  const [getStatusList] = requestWrapper({
    requestFunction: notificationService.getStatusList,
    onSuccess: (data) => {
      setStatusList(data);
    },
    onError: (message) => {
      notificationWarehouse("error", message);
    },
  });

  useEffect(() => {
    getStatusList();
  }, []);

  return (
    <Select
      style={{ width: "100%" }}
      placeholder="Выберите статус"
      showSearch={showSearch}
      optionFilterProp="children"
      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
      value={value}
      onChange={onChange}
    >
      {statusList?.map((el) => (
        <Option key={el.id} value={el.id}>
          {el.name}
        </Option>
      ))}
    </Select>
  );
};

export default SelectStatusEvent;
