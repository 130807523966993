import { Button, DatePicker, Form, Modal, Row, Spin, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import React, { useState } from "react";

import { TypeLogAction } from "./const/const";
import actionLogService from "../../../../../services/actionLogService";
import { notificationWarehouse } from "../../../../../utils/helpers";
import { requestWrapper } from "../../../../../utils/requestWrapper";
import SelectAuthorEvent from "../../../../select/SelectAuthorEvent";
import SelectStatusEvent from "../../../../select/SelectStatusEvent";

const { Text } = Typography;
const formatDateLog = "DD MMM YY г. HH:mm:ss";

const EventLogItemModal = ({ active, setActive, eventType, selectedEventLogItem, setSelectedItem, timetable }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    title: null,
    subTitle: null,
    description: null,
  });
  const [form] = Form.useForm();

  const handleClose = () => {
    setActive(true);
    setSelectedItem(null);
  };
  const [createEventLog] = requestWrapper({
    requestFunction: actionLogService.createEventLog,
    onSuccess: () => {},
    onError: (message) => {
      notificationWarehouse("error", message);
    },
    setLoading: setLoading,
  });

  const [editEventLog] = requestWrapper({
    requestFunction: actionLogService.editEventLog,
    onSuccess: () => {},
    onError: (message) => {
      notificationWarehouse("error", message);
    },
    setLoading: setLoading,
  });

  const [removeEventLog] = requestWrapper({
    requestFunction: actionLogService.removeEventLog,
    onSuccess: (res) => {
      notificationWarehouse("success", res.message);
      handleClose();
    },
    onError: (message) => {
      notificationWarehouse("error", message);
    },
  });

  const handleChangeStatus = (status) => {
    setData((prevData) => ({
      ...prevData,
      title: status,
    }));
  };

  const handleChangeUser = (user) => {
    setData((prevData) => ({
      ...prevData,
      subTitle: user,
    }));
  };

  const handleChangeDate = (date) => {
    const dateString = dayjs(date).format("YYYY-MM-DD HH:mm:ss");

    setData((prevData) => ({
      ...prevData,
      description: dateString,
    }));
  };

  return (
    <Modal
      title={`${eventType === "create" ? TypeLogAction.create : eventType === "edit" ? TypeLogAction.edit : TypeLogAction.delete}`}
      width={440}
      open={active}
      onCancel={handleClose}
      footer={
        <Row justify={"space-between"}>
          {eventType === "create" || eventType === "edit" ? (
            <>
              <Button style={{ width: "194px" }} onClick={handleClose}>
                Отмена
              </Button>
              {eventType === "edit" ? (
                <Button
                  disabled={data.title === null && data.subTitle === null && data.description === null}
                  style={{ width: "194px" }}
                  type="primary"
                  onClick={() => {
                    editEventLog({ ...data, id: selectedEventLogItem.id });
                    handleClose();
                  }}
                >
                  Сохранить изменения
                </Button>
              ) : (
                <Button
                  disabled={data.title === null || data.subTitle === null || data.description === null}
                  style={{ width: "194px" }}
                  type="primary"
                  onClick={() => {
                    const newData = { ...data, timetableId: timetable.id };

                    createEventLog(newData);
                    handleClose();
                  }}
                >
                  Добавить
                </Button>
              )}
            </>
          ) : (
            <>
              <TextArea
                value={
                  `${selectedEventLogItem.title}\n` +
                  `${selectedEventLogItem.subTitle}\n` +
                  `${selectedEventLogItem.description}`
                }
                disabled
                style={{ color: "#4c4c4c" }}
                rows={3}
              />
              <Text type="secondary" style={{ marginTop: "5px", padding: "10px" }}>
                Событие будет навсегда удалено из истории. Вы уверены, что хотите его удалить?
              </Text>
              <Button style={{ width: "194px" }} onClick={handleClose}>
                Нет
              </Button>
              <Button
                style={{ width: "194px" }}
                danger
                onClick={() => {
                  if (selectedEventLogItem.id) {
                    removeEventLog(selectedEventLogItem.id);
                  }
                }}
              >
                Да, удалить
              </Button>
            </>
          )}
        </Row>
      }
    >
      <Spin spinning={loading}>
        {eventType !== "delete" && (
          <Form form={form}>
            <Form.Item
              layout="vertical"
              label="Статус"
              name="title"
              style={{ margin: 0 }}
              initialValue={selectedEventLogItem?.title}
              rules={[
                {
                  required: true,
                },
              ]}
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
            >
              <SelectStatusEvent value={selectedEventLogItem?.title} onChange={handleChangeStatus} />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Автор"
              name="subTitle"
              style={{ margin: 0 }}
              initialValue={selectedEventLogItem?.subTitle}
              rules={[
                {
                  required: true,
                },
              ]}
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
            >
              <SelectAuthorEvent
                value={selectedEventLogItem?.subTitle}
                onChange={handleChangeUser}
                stateId={timetable.stateId}
              />
            </Form.Item>
            <Form.Item
              layout="vertical"
              label="Выберите дату и время"
              name="date"
              style={{ margin: 0 }}
              initialValue={
                selectedEventLogItem?.description ? dayjs(selectedEventLogItem.description, formatDateLog) : null
              }
              rules={[
                {
                  required: true,
                },
              ]}
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
            >
              <DatePicker style={{ width: "100%" }} showTime format={formatDateLog} onChange={handleChangeDate} />
            </Form.Item>
          </Form>
        )}
      </Spin>
    </Modal>
  );
};

export default EventLogItemModal;
