import { ConfigProvider } from "antd";
import locale from "antd/locale/ru_RU";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/ru";
import React, { createContext } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { VersionDisplay } from "./components/versionDisplay/versionDisplay";
import CalendarStore from "./store/CalendarStore";
import NotificationStore from "./store/NotificationStore";
import SocketStore from "./store/SocketStore";
import UserStore from "./store/UserStore";
import packageJson from "../package.json";

dayjs.locale("ru");
dayjs.extend(utc);
dayjs.extend(localizedFormat);

const user = new UserStore();
const socket = new SocketStore();
const calendar = new CalendarStore();
const notification = new NotificationStore();
const version = packageJson.version;

export const Context = createContext({
  user,
  socketStore: socket,
  calendarStore: calendar,
  notificationStore: notification,
});

createRoot(document.getElementById("root")).render(
  <Context.Provider
    value={{
      user,
      calendarStore: calendar,
      socketStore: socket,
      notificationStore: notification,
    }}
  >
    <ConfigProvider locale={locale}>
      <App />
      <VersionDisplay version={version} />
    </ConfigProvider>
  </Context.Provider>
);
