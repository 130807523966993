import {
  AuditOutlined,
  BarsOutlined,
  CalendarOutlined,
  CheckSquareOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { useContext, useEffect, useState, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { Context } from "../../";
import useRouter from "../../hooks/custom-hooks/useRouter";
import {
  APPROVE,
  DRIVER_INFO,
  DRIVER_REPORT,
  CANCEL_REPORT,
  CONTAINER_REPORT,
  RECORDS_LIST,
  STORAGE_CHEKHOV_ROUTE,
  STORAGE_CHEKHOV_TEMPLATE_ROUTE,
  STORAGE_KZ_ROUTE,
  STORAGE_KZ_TEMPLATE_ROUTE,
  STORAGE_NVSB_ROUTE,
  STORAGE_NVSB_TEMPLATE_ROUTE,
  SUPPLIER_ROUTE,
  TRANSPORT_COMPANY_ROUTE,
  ADDITIONAL_REPORT,
  ADMINISTRATION_USERS,
  ADMINISTRATION_ACCESS_USERS,
  WEIGHT_REPORT,
  ADMINISTRATION_PRIVILEGED_OPERATIONS,
  CARRIER_ROUTE,
  TRANSPORT_ROUTE,
  ADMINISTRATION_PAGE_ACTION_HISTORY,
} from "../../utils/consts";
import { checkAccess } from "../../utils/helpers";
import { A } from "../../utils/Styled";

const pathToKeyMap = {
  [STORAGE_CHEKHOV_TEMPLATE_ROUTE]: { key: "1_template_chekhov", parentKey: "1_template" },
  [STORAGE_NVSB_TEMPLATE_ROUTE]: { key: "2_template_nvsb", parentKey: "1_template" },
  [STORAGE_KZ_TEMPLATE_ROUTE]: { key: "3_template_kz", parentKey: "1_template" },
  [STORAGE_CHEKHOV_ROUTE]: { key: "1_calendar_chekhov", parentKey: "1_calendar" },
  [STORAGE_NVSB_ROUTE]: { key: "2_calendar_nvsb", parentKey: "1_calendar" },
  [STORAGE_KZ_ROUTE]: { key: "3_calendar_kz", parentKey: "1_calendar" },
  [APPROVE]: { key: "1_approve", parentKey: null },
  [DRIVER_INFO]: { key: "driver_info", parentKey: null },
  [RECORDS_LIST]: { key: "records_list_1", parentKey: null },
  [DRIVER_REPORT]: { key: "driver_report_time", parentKey: "1_report" },
  [ADDITIONAL_REPORT]: { key: "report_additional_tasks", parentKey: "1_report" },
  [CONTAINER_REPORT]: { key: "report_container", parentKey: "1_report" },
  [CANCEL_REPORT]: { key: "cancel_report", parentKey: "1_report" },
  [WEIGHT_REPORT]: { key: "weight_report", parentKey: "1_report" },
  [SUPPLIER_ROUTE]: { key: "1_supplier", parentKey: "1_handbook" },
  [CARRIER_ROUTE]: { key: "3_carrier", parentKey: "1_handbook" },
  [TRANSPORT_COMPANY_ROUTE]: { key: "2_transport_company", parentKey: "1_handbook" },
  [TRANSPORT_ROUTE]: { key: "4_transport", parentKey: "1_handbook" },
  [ADMINISTRATION_USERS]: { key: "users", parentKey: "administration" },
  [ADMINISTRATION_ACCESS_USERS]: { key: "access_users", parentKey: "administration" },
  [ADMINISTRATION_PRIVILEGED_OPERATIONS]: { key: "pre_emphasized_operations", parentKey: "administration" },
  [ADMINISTRATION_PAGE_ACTION_HISTORY]: { key: "action_history", parentKey: "administration" },
};

const getPathKey = (pathname) => {
  const entry = Object.entries(pathToKeyMap).find(([path]) => pathname.includes(path));

  return entry ? entry[1].key : "";
};

const getOpenKey = (pathname) => {
  const entry = Object.entries(pathToKeyMap).find(([path]) => pathname.includes(path));

  return entry ? entry[1].parentKey : "";
};

const NavBar = ({ handleNavBar }) => {
  const { user } = useContext(Context);
  const { location } = useRouter();
  const [openKeys, setOpenKeys] = useState([]);
  const [selectable, setSelectable] = useState([]);

  useEffect(() => {
    const openKey = getOpenKey(location.pathname);
    const pathKey = getPathKey(location.pathname);

    setSelectable(pathKey);

    if (openKey && !openKeys.includes(openKey)) {
      setOpenKeys((prevKeys) => [...prevKeys, openKey]);
    }
  }, [location.pathname]);

  const handleOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    const rootSubmenuKeys = ["1_calendar", "1_template", "1_report", "administration", "1_handbook", "1_instruction"];

    if (rootSubmenuKeys.includes(latestOpenKey)) {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    } else {
      setOpenKeys(keys);
    }
  };

  const { userAccess, userStorages, userType, userId, isCheckAccessFull123 } = useMemo(() => {
    if (!user.user) {
      return {
        userAccess: [],
        userStorages: { chekhov: null, nvsb: null, kz: null },
        userType: null,
        userId: null,
        isCheckAccessFull123: false,
      };
    }

    const userInfo = user.user;

    return {
      userAccess: userInfo.access ?? [],
      userStorages: userInfo.storage_access ?? {},
      userType: userInfo.type_id,
      userId: userInfo.id,
      isCheckAccessFull123: checkAccess([1, 2, 3], userInfo.storage_access, "full"),
    };
  }, [user]);

  const getItem = (label, key, icon, children, type) => ({
    key,
    icon,
    children,
    label,
    type,
  });

  const items = useMemo(
    () =>
      [
        (userAccess.includes(1) || checkAccess([1, 2, 3], userStorages, ["full", "view", "update", "view-all"])) &&
          getItem("Календарь", "1_calendar", <CalendarOutlined />, [
            userStorages.chekhov && getItem(<NavLink to={STORAGE_CHEKHOV_ROUTE}>Чехов</NavLink>, "1_calendar_chekhov"),
            userStorages.nvsb && getItem(<NavLink to={STORAGE_NVSB_ROUTE}>Новосибирск</NavLink>, "2_calendar_nvsb"),
            userStorages.kz && getItem(<NavLink to={STORAGE_KZ_ROUTE}>Казахстан</NavLink>, "3_calendar_kz"),
          ]),
        (userAccess.includes(2) || isCheckAccessFull123) &&
          getItem("Шаблон расписания", "1_template", <CalendarOutlined />, [
            (userAccess.includes(2) || checkAccess(1, userStorages, ["full", "update"])) &&
              getItem(<NavLink to={STORAGE_CHEKHOV_TEMPLATE_ROUTE}>Чехов</NavLink>, "1_template_chekhov"),
            (userAccess.includes(2) || checkAccess(2, userStorages, ["full", "update"])) &&
              getItem(<NavLink to={STORAGE_NVSB_TEMPLATE_ROUTE}>Новосибирск</NavLink>, "2_template_nvsb"),
            (userAccess.includes(2) || checkAccess(3, userStorages, ["full", "update"])) &&
              getItem(<NavLink to={STORAGE_KZ_TEMPLATE_ROUTE}>Казахстан</NavLink>, "3_template_kz"),
          ]),
        (userAccess.includes(3) || isCheckAccessFull123) &&
          getItem(<NavLink to={APPROVE}>Подтверждение</NavLink>, "1_approve", <CheckSquareOutlined />),
        (userAccess.includes(4) || isCheckAccessFull123) &&
          getItem(<NavLink to={DRIVER_INFO}>Информация о водителях</NavLink>, "driver_info", <BarsOutlined />),
        (userAccess.includes(7) || isCheckAccessFull123) &&
          getItem(<NavLink to={RECORDS_LIST}>Таблица погрузок</NavLink>, "records_list_1", <BarsOutlined />),
        (userAccess.includes(8) || isCheckAccessFull123) &&
          getItem("Отчеты", "1_report", <AuditOutlined />, [
            (userAccess.includes(11) || isCheckAccessFull123) &&
              getItem(<NavLink to={DRIVER_REPORT}>Опоздания/задержки</NavLink>, "driver_report_time"),
            (userAccess.includes(9) || isCheckAccessFull123) &&
              getItem(<NavLink to={ADDITIONAL_REPORT}>Доп. заявки</NavLink>, "report_additional_tasks"),
            (userAccess.includes(12) || isCheckAccessFull123) &&
              getItem(<NavLink to={CONTAINER_REPORT}>Разгрузки</NavLink>, "report_container"),
            (userAccess.includes(14) || isCheckAccessFull123) &&
              getItem(<NavLink to={CANCEL_REPORT}>Отмененные</NavLink>, "cancel_report"),
            (userAccess.includes(15) || isCheckAccessFull123) &&
              getItem(<NavLink to={WEIGHT_REPORT}>Факт/План обьем</NavLink>, "weight_report"),
          ]),
        (userType === 5 || userId === 3455 || user.id === 4272) &&
          getItem("Администрирование", "administration", <SettingOutlined />, [
            getItem(<NavLink to={ADMINISTRATION_USERS}>Доступ к филиалам</NavLink>, "users", null),
            getItem(<NavLink to={ADMINISTRATION_ACCESS_USERS}>Доступ к складам</NavLink>, "access_users", null),
            process.env.REACT_APP_TEST_START === "true" &&
              getItem(
                <NavLink to={ADMINISTRATION_PRIVILEGED_OPERATIONS}>Привелигированные действия</NavLink>,
                "pre_emphasized_operations",
                null
              ),
            getItem(
              <NavLink to={ADMINISTRATION_PAGE_ACTION_HISTORY}>История действий</NavLink>,
              "action_history",
              null
            ),
          ]),
        (userAccess.includes(5) || isCheckAccessFull123) &&
          getItem("Справочники", "1_handbook", <MailOutlined />, [
            getItem(<NavLink to={SUPPLIER_ROUTE}>Поставщики</NavLink>, "1_supplier"),
            getItem(<NavLink to={CARRIER_ROUTE}>Перевозчики</NavLink>, "3_carrier"),
            getItem(<NavLink to={TRANSPORT_COMPANY_ROUTE}>Транспортные компании</NavLink>, "2_transport_company"),
            user.user.type_id === 5 &&
              getItem(<NavLink to={TRANSPORT_ROUTE}>Транспортные средства</NavLink>, "4_transport"),
          ]),
        getItem("База знаний", "1_instruction", <MailOutlined />, [
          getItem(
            <A href="https://hd.hd-resanta.ru/knowledge/Poryadok_otgryzok_tovara_s_CS">Порядок отгрузок товара с ЦС</A>,
            "1_instruction_sub"
          ),
          getItem(
            <A href="https://hd.hd-resanta.ru/knowledge/instruction_sklad">Работа с разделом склад</A>,
            "2_instruction_sub"
          ),
        ]),
      ].filter(Boolean),
    [userAccess, userStorages, userType, userId, isCheckAccessFull123, user]
  );

  return (
    <Menu
      onClick={handleNavBar}
      items={items}
      theme="dark"
      mode="inline"
      openKeys={openKeys}
      selectedKeys={[selectable]}
      onOpenChange={handleOpenChange}
      onSelect={(e) => setSelectable(e.key)}
      style={{ overflow: "hidden" }}
    />
  );
};

export default NavBar;
