import { Modal, Spin, Steps } from "antd";
import React, { useState, useEffect } from "react";
import notificationService from "../../../../services/notificationService";
import { notificationWarehouse } from "../../../../utils/helpers";
import { requestWrapper } from "../../../../utils/requestWrapper";

const EventLogModal = ({ active, setActive, timetable }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const [getEventLogs] = requestWrapper({
    requestFunction: notificationService.getEventLogs,
    onSuccess: (data) => {
      if (data?.length) {
        setItems(data);
      } else setItems([]);
    },

    onError: (message) => {
      notificationWarehouse("error", message);
    },
    setLoading: setLoading,
  });

  useEffect(() => {
    if (active) {
      setLoading(true);
      getEventLogs(timetable.id);
    }
  }, [active]);

  return (
    <Modal
      title={"История события"}
      open={active}
      footer={false}
      onCancel={() => {
        setActive(false);
      }}
    >
      <Spin spinning={loading}>
        <Steps progressDot direction="vertical" current={items.length} items={items} />
      </Spin>
    </Modal>
  );
};

export default EventLogModal;
