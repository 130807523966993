import {
  CheckSquareOutlined,
  CloseSquareOutlined,
  DeleteOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { Button, Col, Modal, Row, Skeleton } from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import React, { useEffect, useState, useContext } from "react";
import "./OrderInfo.css";
import { Context } from "../../../..";
import useRouter from "../../../../hooks/custom-hooks/useRouter";
import callService from "../../../../services/callService";
import notificationService from "../../../../services/notificationService";
import templateService from "../../../../services/templateService";
import timetableService from "../../../../services/timetableService";
import userService from "../../../../services/userService";
import { allStatusKeyValues, clientStatus, EActionKeys } from "../../../../utils/consts";
import { notificationWarehouse } from "../../../../utils/helpers";
import { requestWrapper } from "../../../../utils/requestWrapper";
import { Div } from "../../../../utils/Styled";
import ChangeTimeDatePicker from "../../../date-picker/ChangeTimeDatePicker";
import SpareParts from "../../../spareParts/SpareParts";
import EventLogModal from "../../event/event-log/EventLogModal";
import NoteModal from "../../NoteModal";
import ShoppingCart from "../../ShoppingCart";

const OrderInfo = ({ active, setActive, data, setData, update, week, sklad_use, startDate, endDate, loading }) => {
  const { push } = useRouter();
  const { user, socketStore } = useContext(Context);
  const [comment, setComment] = useState(null);
  const [statusCall, setStatusCall] = useState(null);
  const [loadCancel, setLoadCancel] = useState(false);
  const [loadingCall, setLoadingCall] = useState(false);
  const [isNoteEvent] = useState(false);
  const [isActiveNoteModal, setIsActiveNoteModal] = useState(false);
  const [shoppingCartModal, setShoppingCartModal] = useState(false);
  const [isActiveEventLogModal, setIsActiveEventLogModal] = useState(false);

  const isAccessUserNote = [5, 15, 49, 10, 48, 19, 37].includes(user.user.type_id);

  const handleClose = () => {
    setActive(false);
    setData(null);
    socketStore.disconnectedUserTimetable(data?.id);
  };

  const { saveTimetable } = timetableService.wrapperStorageTimetableEdit({
    onSuccessful: () => {
      notificationWarehouse("success", "Запись дополнена");
      update(sklad_use, startDate, endDate);
      setComment(null);
      setActive(false);
      setLoadCancel(false);
    },
    onError: (error) => {
      notificationWarehouse("error", `Произошла ошибка: ${error}`);
    },
  });

  const { createNotification } = notificationService.useNotificationCreate({
    onSuccessful: () => {},
    onError: () => {},
  });

  const [sendEmail] = requestWrapper({
    requestFunction: timetableService.email,
    onSuccess: () => {},
    onError: () => {},
  });

  const { gateCheck } = notificationService.wrapperGateCheck({
    onSuccessful: (response) => {
      if (response === false) {
        saveTimetable({
          status: allStatusKeyValues.entryInfo.key,
          id: data?.id,
        });
        createNotification({
          timetable_id: data?.id,
          action_id: 4,
        });
        sendEmail({
          id: data?.id,
          action_key: EActionKeys.sentEditting,
        });
        socketStore.changeStatusTimetable(data?.id);
        push(`/loading_registration/${data?.id}`);
      } else {
        notificationWarehouse("error", "Кладовщик уже подтвердил запись и назначил номер ворот");
      }
    },
    onError: (error) => {
      notificationWarehouse("error", error);
    },
  });

  const { createTimetable } = timetableService.wrapperStorageTimetableCreate({
    onSuccessful: (data) => {
      if (data.status === allStatusKeyValues.entryInfo.key) {
        push(`/loading_registration/${data.id}`);
      }

      if (data.status === allStatusKeyValues.cancel.key) {
        saveTimetable({
          id: data.id,
          status: allStatusKeyValues.cancel.key,
        });
        createNotification({
          timetable_id: data?.id,
          action_id: 2,
        });
        sendEmail({
          id: data?.id,
          action_key: EActionKeys.loadingRecordCancelled,
        });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { getIsCheckTemplateCancel } = timetableService.wrapperCheckTemplateCancel({
    onSuccessful: (data) => {
      if (data?.message) {
        notificationWarehouse("success", data?.message);
      }
    },
    onError: (message) => {
      notificationWarehouse("error", message);
    },
  });

  const { createTimetable: createTimetableAction } = timetableService.wrapperStorageTimetableCreate({
    onSuccessful: () => {
      update(sklad_use, startDate, endDate);
    },
    onError: () => {},
  });

  const { callStatus } = callService.wrapperCallStatus({
    onSuccessful: (data) => {
      setStatusCall(data?.status);
    },
    onError: () => {
      notificationWarehouse("error", "Ошибка получния статуса звонка");
    },
  });

  const { call } = callService.wrapperCall({
    onSuccessful: (data) => {
      notificationWarehouse("success", data?.message);
      setLoadingCall(false);
    },
    onError: (message) => {
      notificationWarehouse("error", message);
      setLoadingCall(false);
    },
  });

  const repeatCall = async () => {
    setLoadingCall(true);
    const storekeeper = await userService.getUser(data.storekeeper);

    call(data.id, data.storekeeper, data?.gate, storekeeper.tel);
  };

  const createNewTimetable = async (extraFields = {}) => {
    const newData = {
      date: data?.startTime,
      event_duration: data.eventDuration,
      innGroup: data.innGroup,
      inn: data.inn,
      sklad_use: sklad_use,
      state_id: data.stateId,
      status: allStatusKeyValues.entryInfo.key,
      weight: data.weight,
      record_volume: data.record_volume,
      type: clientStatus.pogrz,
      week: week,
      template: true,
      ...extraFields,
    };

    return createTimetableAction({ ...newData });
  };

  const getStatusText = () =>
    statusCall === "success"
      ? "Звонок совершен"
      : statusCall === "work" || statusCall === "new"
        ? "Ожидает звонка"
        : statusCall === "fail"
          ? "Не удалось дозвониться"
          : "Звонок не был совершен";

  useEffect(() => {
    if (data?.gate) {
      callStatus(data?.id);
    }
  }, [data]);

  useEffect(() => {
    if (active && data?.id) {
      socketStore.connectionTimetable(data.id);
      socketStore.setActiveModal(setActive);
    }
  }, [active]);

  const btnEnterInfo = async () => {
    const record = await getIsCheckTemplateCancel(data?.startTime, data.innGroup, data.stateId);

    if (record.statusRecord === allStatusKeyValues.cancel.key) {
      update(sklad_use, startDate, endDate);
      handleClose();

      return;
    } else if (record.statusRecord) {
      push(`/loading_registration/${record.idRecord}`);
    } else if (data.status === "tamplate" || data.status === clientStatus.template) {
      const newData = {
        date: data?.startTime,
        event_duration: data.eventDuration,
        inn: data.inn,
        innGroup: data.innGroup,
        sklad_use: sklad_use,
        state_id: data.stateId,
        status: allStatusKeyValues.entryInfo.key,
        weight: data.weight,
        record_volume: data.record_volume,
        type: clientStatus.pogrz,
        week: week,
        template: true,
        createdAt: data.updatedAt,
        authorId: data.logs === null ? 1128 : data.logs,
      };

      createTimetable({ ...newData });
    }
  };

  const btnCancel = () => {
    setLoadCancel(true);
    if (data.status === clientStatus.template) {
      const newData = {
        date: data?.startTime,
        event_duration: data.eventDuration,
        innGroup: data.innGroup,
        inn: data.inn,
        sklad_use: sklad_use,
        state_id: data.stateId,
        status: allStatusKeyValues.cancel.key,
        weight: data.weight,
        record_volume: data.record_volume,
        type: clientStatus.pogrz,
        week: week,
        template: true,
        createdAt: data.updatedAt,
        authorId: data.logs === null ? 1128 : data.logs,
      };

      createTimetable({ ...newData });
    } else {
      saveTimetable({
        id: data.id,
        status: allStatusKeyValues.cancel.key,
      });
      createNotification({
        timetable_id: data?.id,
        action_id: 2,
      });
      sendEmail({
        id: data?.id,
        action_key: EActionKeys.loadingRecordCancelled,
      });
    }
  };

  const [infoTimetable] = requestWrapper({
    requestFunction: timetableService.moreInfoTimetable,
    onSuccess: () => {
      notificationWarehouse("success", "Информация получена");
    },
  });

  const moreInfoTimetable = () => {
    try {
      infoTimetable(data.id);
    } catch (error) {
      notificationWarehouse("error", "Ошибка");
    }
  };

  const [removeTimetable] = requestWrapper({
    requestFunction: timetableService.removeTimetable,
    onSuccess: (resData) => {
      socketStore.removeTimetable(data?.id);
      update(sklad_use, startDate, endDate);
      notificationWarehouse("success", resData.message);
      handleClose();
    },
    onError: (message) => {
      notificationWarehouse("error", message);
    },
  });

  const { deleteTemplate } = templateService.wrapperTemplateDelete({
    onSuccessful: () => {
      notificationWarehouse("success", "Запись успешно удалена");
      handleClose();
      update(sklad_use, startDate, endDate);
    },
    onError: (error) => {
      notificationWarehouse("error", `Произошла ошибка: ${error}`);
    },
  });

  const Footer = () => [
    <Row key={"row-text-area-comment"} style={{ marginBottom: 10 }}>
      {data?.button.includes("reject") && (
        <TextArea
          placeholder={"Введите причину отказа"}
          value={comment ? comment : null}
          style={{ marginTop: "4%", borderColor: "red", width: "25em" }}
          rows={4}
          onChange={(event) => {
            setComment(event.target.value);
          }}
        />
      )}
    </Row>,
    <Row gutter={[2, 2]} key={"row-btn-create-timetable"} justify={"space-between"}>
      {data?.button?.includes("vvod") && (
        <Col>
          <Button type="primary" onClick={btnEnterInfo}>
            Ввести информацию
          </Button>
        </Col>
      )}
      {data?.button?.includes(allStatusKeyValues.cancel.key) && (
        <Col>
          <Button danger loading={loadCancel} onClick={btnCancel}>
            Отмена погрузки
          </Button>
        </Col>
      )}
      {data?.button?.includes("more") && (
        <Col>
          <Button
            onClick={() => {
              push(`/show_record/${data.id}`);
            }}
          >
            Подробнее
          </Button>
        </Col>
      )}
      {data?.button.includes("reject") && (
        <Col>
          <Button
            disabled={!comment}
            danger
            onClick={() => {
              saveTimetable({
                status: allStatusKeyValues.refusal.key,
                id: data?.id,
                comment: comment,
              });
              createNotification({
                timetable_id: data?.id,
                action_id: 3,
              });
              sendEmail({
                id: data?.id,
                action_key: EActionKeys.loadingRecordReject,
              });
            }}
          >
            <CloseSquareOutlined />
            Отклонить погрузку
          </Button>
        </Col>
      )}
      {data?.button.includes("changeDate") && data?.status !== allStatusKeyValues.blocked.key && (
        <Col>
          <ChangeTimeDatePicker
            data={data}
            week={week}
            warehouse={sklad_use}
            update={() => {
              update(sklad_use, startDate, endDate);
              handleClose();
            }}
          />
        </Col>
      )}
      {data?.button.includes("removeTimetable") && (
        <Col>
          <Button
            danger
            type="primary"
            onClick={() => {
              if (data.id) {
                removeTimetable(data.id);
              }
            }}
          >
            <DeleteOutlined /> Удалить
          </Button>
        </Col>
      )}
      {data?.button.includes("approve") && (
        <Col>
          <Button
            disabled={!!comment}
            type="primary"
            onClick={() => {
              saveTimetable({
                status: allStatusKeyValues.entryInfo.key,
                id: data?.id,
                comment: null,
              });
              createNotification({
                timetable_id: data?.id,
                action_id: 5,
              });
              sendEmail({
                id: data?.id,
                action_key: EActionKeys.waitingInformationEntry,
              });
            }}
          >
            <CheckSquareOutlined /> Подтвердить
          </Button>
        </Col>
      )}
      {(data?.gate === null || data?.gate === undefined) && data?.button?.includes("edit") && (
        <Col>
          <Button
            onClick={() => {
              gateCheck(data?.id);
            }}
          >
            Редактировать
          </Button>
        </Col>
      )}
      {data?.button?.includes("in") && (
        <Col>
          <Button
            onClick={() => {
              saveTimetable({
                id: data.id,
              });
              createNotification({
                timetable_id: data?.id,
                action_id: 9,
              });
            }}
          >
            Пропустить машину
          </Button>
        </Col>
      )}
      {data?.button?.includes("out") && (
        <Col>
          <Button
            onClick={() => {
              saveTimetable({
                id: data.id,
              });
              createNotification({
                timetable_id: data?.id,
                action_id: 10,
              });
            }}
          >
            Выпустить машину
          </Button>
        </Col>
      )}
      {data?.button?.includes("unReserve") && data?.status === allStatusKeyValues.blocked.key && (
        <Col>
          <Button
            onClick={() => {
              if (data?.id < 5368) deleteTemplate(data.id);
              else removeTimetable(data.id);
            }}
          >
            Разблокировать запись
          </Button>
        </Col>
      )}
    </Row>,
  ];

  const MainContent = () => (
    <>
      <NoteModal active={isActiveNoteModal} setActive={setIsActiveNoteModal} timetable={data} />
      <ShoppingCart active={shoppingCartModal} setActive={setShoppingCartModal} timetable={data} />
      <EventLogModal active={isActiveEventLogModal} setActive={setIsActiveEventLogModal} timetable={data} />
      <Col>
        <div>Дата погрузки:</div>
        <Div>{dayjs.utc(data?.startTime).format("DD-MM-YYYY HH:mm")}</Div>

        <div>Компания: </div>
        <Div>{data?.companyName}</Div>

        {data?.status !== allStatusKeyValues.blocked.key && (
          <>
            <div>Филиал:</div>
            <Div>{data?.stateName}</Div>
          </>
        )}

        {!!data?.date_back && (
          <>
            <div>Дата разгрузки на филиале:</div>
            <Div>{data?.date_back}</Div>
          </>
        )}

        {!!data?.order_number && (
          <>
            <div>Номер заказа:</div>
            <Div>{data?.order_number}</Div>
          </>
        )}

        {data?.status !== allStatusKeyValues.blocked.key && (
          <>
            <div>Объем (куб.метры):</div>
            <Div>{data?.record_volume}</Div>
          </>
        )}

        {!!data?.gate && (
          <>
            <div>Номер ворот:</div>
            <Div>{data?.gate}</Div>

            <div>Статус звонка водителю:</div>
            <Div>{getStatusText()}</Div>

            {statusCall === "fail" && data?.button?.includes("repeatCall") && (
              <Button type="primary" loading={loadingCall} onClick={repeatCall}>
                Позвонить водителю
              </Button>
            )}
          </>
        )}
        {[5, 37, 54].includes(user.user.type_id) && data?.status !== allStatusKeyValues.blocked.key ? (
          <>
            <div>Текущий статус:</div>
            <Div>{data?.actionName}</Div>
          </>
        ) : (
          <></>
        )}
        <SpareParts
          data={data}
          setData={setData}
          startDate={startDate}
          endDate={endDate}
          update={update}
          warehouse={sklad_use}
          week={week}
        />
      </Col>
    </>
  );

  const getEventById = timetableService.getEventById;

  const createNewTimetableIfNotExist = async () =>
    createNewTimetable().then(async (res) => {
      const timetable = await getEventById(res.id);

      setData((prev) => ({ ...prev, ...timetable.data.event.extendedProps }));

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 100);
      });
    });

  const openNoteModal = async () => {
    if (data.status === "tamplate" || data.status === clientStatus.template) {
      createNewTimetableIfNotExist().then(() => {
        setIsActiveNoteModal(true);
      });
    } else setIsActiveNoteModal(true);
  };

  const openShoppingCartModal = () => {
    if (data.status === "tamplate" || data.status === clientStatus.template) {
      createNewTimetableIfNotExist().then(() => {
        setShoppingCartModal(true);
      });
    } else setShoppingCartModal(true);
  };

  const openEventLogModal = () => {
    if (data.status === "tamplate" || data.status === clientStatus.template) {
      notificationWarehouse("warning", "История не доступна");
    } else setIsActiveEventLogModal(true);
  };

  return (
    <Modal
      width={700}
      title={
        <Row justify={"space-between"} style={{ marginRight: "30px" }}>
          <Col className="title">Запись на погрузку</Col>
          <Row justify={"space-between"} align={"middle"}>
            <Col>
              <HistoryOutlined className="chat shopping-cart" onClick={openEventLogModal} />
            </Col>
            <Col>
              <ShoppingCartOutlined className="chat shopping-cart" onClick={openShoppingCartModal} />
            </Col>
            {user.user.type_id === 5 && data?.status !== clientStatus.template ? (
              <Col>
                <InfoCircleOutlined className="chat shopping-cart" onClick={moreInfoTimetable} />
              </Col>
            ) : (
              <></>
            )}
            {isAccessUserNote ? (
              <Col>
                <img
                  src={isNoteEvent ? "/assets/img/mark_unread_chat.svg" : "/assets/img/chat.svg"}
                  className={"chat"}
                  alt={"Note"}
                  onClick={openNoteModal}
                />
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Row>
      }
      open={active}
      onCancel={() => {
        handleClose();
      }}
      footer={
        !loading ? Footer() : [<Skeleton loading={loading} active key={"footer-skeleton"} paragraph={{ rows: 0 }} />]
      }
    >
      <Skeleton
        loading={loading}
        active
        paragraph={{
          rows: 8,
        }}
      >
        <MainContent />
      </Skeleton>
    </Modal>
  );
};

export default OrderInfo;
