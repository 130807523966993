// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat {}

.chat:hover {
   transform: scale(1.1);
   transition: .1s all;
   cursor: pointer;
}

.shopping-cart {
   font-size: 40px;
   color: #999;
   width: 45px;
   height: 39px;
}`, "",{"version":3,"sources":["webpack://./src/components/modals/order/info/OrderInfo.css"],"names":[],"mappings":"AAAA,OAAO;;AAEP;GACG,qBAAqB;GACrB,mBAAmB;GACnB,eAAe;AAClB;;AAEA;GACG,eAAe;GACf,WAAW;GACX,WAAW;GACX,YAAY;AACf","sourcesContent":[".chat {}\n\n.chat:hover {\n   transform: scale(1.1);\n   transition: .1s all;\n   cursor: pointer;\n}\n\n.shopping-cart {\n   font-size: 40px;\n   color: #999;\n   width: 45px;\n   height: 39px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
