export const LOGIN_ROUTE = "/login";
export const TRANSPORT_COMPANY_ROUTE = "/transport_company";
export const SUPPLIER_ROUTE = "/supplier";
export const CARRIER_ROUTE = "/carrier";
export const TRANSPORT_ROUTE = "/transport";
export const STORAGE_CHEKHOV_ROUTE = "/storage_chekhov";
export const STORAGE_CHEKHOV_TEMPLATE_ROUTE = "/storage_chekhov_template";
export const STORAGE_NVSB_TEMPLATE_ROUTE = "/storage_nvsb_template";
export const STORAGE_KZ_TEMPLATE_ROUTE = "/storage_kz_template";
export const STORAGE_KZ_ROUTE = "/storage_kz";
export const STORAGE_NVSB_ROUTE = "/storage_nvsb";
export const ATTENDANCE = "/attendance";
export const EMPLOYEE_LIST = "/employee_list";
export const DRIVER_REPORT = "/driver_report";
export const CANCEL_REPORT = "/cancel_report";
export const WEIGHT_REPORT = "/weight_report";
export const CONTAINER_REPORT = "/container_report";
export const ADDITIONAL_REPORT = "/additional_report";
export const LOADING_REGISTRATION = "/loading_registration/:id";
export const APPROVE = "/approve";
export const RECORDS_LIST = "/records_list";
export const SHOW_RECORD = "/show_record/:id";
export const DRIVER_INFO = "/driver_info";
export const ADMINISTRATION_USERS = "/administration_users";
export const PAGE_NOT_ACCESS = "/page_not_access";
export const ADMINISTRATION_ACCESS_USERS = "/administration_access_users";
export const ADMINISTRATION_PRIVILEGED_OPERATIONS = "/administration_pre_emphasized_operations";

export const FilesDescription = {
  reg: "Проверка регистрационных данных на сайте ГИБДД",
  poz: "Проверка на нахождение в розыске сайте ГИБДД",
  ogr: "Проверка на наличие ограничений на сайте ГИБДД",
  doc: "Экспедиторская расписка",
  pas: "Паспорт водителя (2, 3 и 5 страницы)",
  vod: "Водительское удостоверение с обеих сторон",
  pts: "Паспорт транспортного средства(птс) с обеих сторон",
  sts: "Свидетельство о регистрации транспортного средства(стс) с обеих сторон",
  sts_p: "Стс прицепа с обеих сторон",
  pts_p: "Птс прицепа с обеих сторон",
  razrgz_kz: "Накладная разгрузки для Казахстана",
};

export const empty = "/assets/img/defaultPic.png";

export const getFileName = (type, data) => {
  const fileArr = data?.filesInfo;

  if (fileArr) {
    return fileArr.find((el) => el?.type === type && el?.obj_id === data?.id)?.name;
  }
};

export const getFileId = (type, data) => data?.filesInfo?.find((el) => el?.type === type)?.id;

export const getFileType = (type, data) => data?.filesInfo?.find((el) => el?.type === type)?.file_type;

export const AccessDescription = {
  full: "Полный доступ",
  update: "Редактирование",
  view: "Просмотр",
  "view-all": "Просмотр по всем филиалам",
  null: "Нет доступа",
};

export const EAccess = {
  full: "full",
  update: "update",
  view: "view",
  "view-all": "view-all",
  null: "null",
};

export const storageDescription = {
  chekhov: 1,
  nvsb: 2,
  kz: 3,
  1: "chekhov",
  2: "nvsb",
  3: "kz",
};

// Пользователи с доступом только к своему филиалу (type_id user)
export const UsersFilialAccess = [1, 2, 3, 4, 9, 11, 17, 18, 48];

// Пользователи с доступом ко всему складу (type_id user)
export const UsersGrandAccess = [5, 10, 15, 18, 24, 44, 48, 49, 67, 70];

export const UserSecureAccess = [37, 54];

export const initUploadFiles = {
  registrationCheck: [],
  restrictionCheck: [],
  wantedCheck: [],
  driverPassport: [],
  driverLicense: [],
  vehicleRegistration: [],
  vehiclePassport: [],
  shippingReceipt: [],
  trailerPassport: [],
};
//TODO СДЕЛАТЬ ENUM
export const DOC_KEY_REGISTRATION_CHECK = "registrationCheck";
export const DOC_KEY_RESTRICTION_CHECK = "restrictionCheck";
export const DOC_KEY_WANTED_CHECK = "wantedCheck";
export const DOC_KEY_DRIVER_PASSPORT = "driverPassport";
export const DOC_KEY_DRIVER_LICENSE = "driverLicense";
export const DOC_KEY_VEHICLE_REGISTRATION = "vehicleRegistration";
export const DOC_KEY_VEHICLE_PASSPORT = "vehiclePassport";
export const DOC_KEY_SHIPPING_RECEIPT = "shippingReceipt";
export const DOC_KEY_TRAILER_PASSPORT = "trailerPassport";

export const ALLOWED_DOCS = 4;
export const INDEX_VOLUME = 20;
export const dateFormat = "YYYY-MM-DD";

/**
 * @deprecated use {@link allStatusKeyValues}
 */
export const allStatus = {
  free: "Свободно",
  blocked: "Не доступно",
  agreed: "Согласовано",
  end: "Завершено",
  refusal: "Отказ",
  cancel: "Запись на погрузку отменена",
  waiting: "Ожидает первичного подтверждения",
  entryInfo: "Ожидает ввода информации",
  redact: "Ожидает редактирования информации",
  waitingInfo: "Ожидает проверки информации",
};

export const allStatusKeyValues = {
  free: {
    value: "Свободно",
    key: "free",
  },
  blocked: {
    value: "Не доступно",
    key: "blocked",
  },
  agreed: {
    value: "Согласовано",
    key: "agreed",
  },
  end: {
    value: "Завершено",
    key: "end",
  },
  refusal: {
    value: "Отказ",
    key: "refusal",
  },
  cancel: {
    value: "Запись на погрузку отменена",
    key: "cancel",
  },
  waiting: {
    value: "Ожидает первичного подтверждения",
    key: "waiting",
  },
  entryInfo: {
    value: "Ожидает ввода информации",
    key: "entryInfo",
  },
  redact: {
    value: "Ожидает редактирования информации",
    key: "redact",
  },
  waitingInfo: {
    value: "Ожидает проверки информации",
    key: "waitingInfo",
  },
};
