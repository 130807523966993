/* eslint-disable no-use-before-define */
import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import ProtectedRoute, { filterProtectedRoutes } from "./hocs/ProtectedRoute";
const AdditionalReport = React.lazy(() => import("./pages/AdditionalReport"));
const AdministrationAccessUsers = React.lazy(
  () => import("./pages/administration/access-users/AdministrationAccessUsers")
);
const PrivilegedOperationsAdministration = React.lazy(
  () => import("./pages/administration/privileged-operations/PrivilegedOperationsAdministration")
);
const AdministrationUsers = React.lazy(() => import("./pages/administration/users/AdministrationUsers"));
const Approve = React.lazy(() => import("./pages/ApproveList"));
const CancelReport = React.lazy(() => import("./pages/CancelReport"));
const ContainerReport = React.lazy(() => import("./pages/ContainerReport"));
const DriverInfo = React.lazy(() => import("./pages/driver-info/DriverInfo"));
const DriverReport = React.lazy(() => import("./pages/reports/driver/DriverReport"));
const EmployeeList = React.lazy(() => import("./pages/EmployeeList"));
const LoadingRegistration = React.lazy(() => import("./pages/LoadingRegistration"));
const PageNotAccess = React.lazy(() => import("./pages/PageNotAccess"));
const RecordList = React.lazy(() => import("./pages/RecordList"));
const WeightReport = React.lazy(() => import("./pages/reports/weight/WeightReport"));
const ShowRecord = React.lazy(() => import("./pages/show-records/ShowRecord"));
const Supplier = React.lazy(() => import("./pages/SupplierList"));
const Carrier = React.lazy(() => import("./pages/CarrierList"));
const Template = React.lazy(() => import("./pages/Template"));
const TimetableNew = React.lazy(() => import("./pages/TimetableNew"));
const TransportCompanyList = React.lazy(() => import("./pages/TransportCompanyList"));
const TransportList = React.lazy(() => import("./pages/TransportList"));

import {
  APPROVE,
  DRIVER_REPORT,
  CONTAINER_REPORT,
  ADDITIONAL_REPORT,
  EMPLOYEE_LIST,
  STORAGE_CHEKHOV_ROUTE,
  STORAGE_CHEKHOV_TEMPLATE_ROUTE,
  STORAGE_KZ_ROUTE,
  STORAGE_KZ_TEMPLATE_ROUTE,
  STORAGE_NVSB_ROUTE,
  STORAGE_NVSB_TEMPLATE_ROUTE,
  SUPPLIER_ROUTE,
  TRANSPORT_COMPANY_ROUTE,
  LOADING_REGISTRATION,
  RECORDS_LIST,
  SHOW_RECORD,
  DRIVER_INFO,
  CANCEL_REPORT,
  ADMINISTRATION_USERS,
  PAGE_NOT_ACCESS,
  ADMINISTRATION_ACCESS_USERS,
  WEIGHT_REPORT,
  ADMINISTRATION_PRIVILEGED_OPERATIONS,
  CARRIER_ROUTE,
  TRANSPORT_ROUTE,
} from "./utils/consts";
import { checkAccess } from "./utils/helpers";
import { Context } from ".";

export const useRoutes = () => {
  const { user } = useContext(Context);

  return (
    <Routes>
      {filterProtectedRoutes(user.user, routes(user.user)).map(({ path, Component, protection }) =>
        protection !== undefined ? (
          <Route key={path} path={path} element={<ProtectedRoute key={"ProtectedRoute"} protection={protection} />}>
            <Route path={path} element={<Component />} />
          </Route>
        ) : (
          <Route key={path} path={path} element={<Component />} />
        )
      )}
      <Route path="*" element={<Navigate to={STORAGE_CHEKHOV_ROUTE} />} />
    </Routes>
  );
};

/**
 * @typedef {Object} TRoutes
 * @property {String} path
 * @property {Number} access
 * @property {React.ComponentType<any> | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>> | undefined} Component
 */

/**
 * @returns {TRoutes[]}
 * */
export const routes = (user) => [
  {
    access: 5,
    path: TRANSPORT_COMPANY_ROUTE,
    Component: TransportCompanyList,
  },
  {
    access: 5,
    path: SUPPLIER_ROUTE,
    Component: Supplier,
  },
  {
    access: 5,
    path: CARRIER_ROUTE,
    Component: Carrier,
  },
  {
    access: 5,
    path: TRANSPORT_ROUTE,
    Component: TransportList,
    protection: {
      condition: user.type_id === 5,
      redirect: STORAGE_CHEKHOV_ROUTE,
    },
  },
  {
    access: 2,
    path: STORAGE_CHEKHOV_TEMPLATE_ROUTE,
    Component: () => <Template warehouse={1} />,
    protection: {
      condition: checkAccess(1, user?.storage_access, ["full", "update"]),
      redirect: STORAGE_NVSB_ROUTE,
    },
  },
  {
    access: 2,
    path: STORAGE_NVSB_TEMPLATE_ROUTE,
    Component: () => <Template warehouse={2} />,
    protection: {
      condition: checkAccess(2, user?.storage_access, ["full", "update"]),
      redirect: STORAGE_KZ_ROUTE,
    },
  },
  {
    access: 2,
    path: STORAGE_KZ_TEMPLATE_ROUTE,
    Component: () => <Template warehouse={3} />,
    protection: {
      condition: checkAccess(3, user?.storage_access, ["full", "update"]),
      redirect: PAGE_NOT_ACCESS,
    },
  },
  {
    access: 1,
    path: STORAGE_CHEKHOV_ROUTE,
    Component: () => <TimetableNew warehouse={1} />,
    protection: {
      condition: user?.storage_access?.["chekhov"],
      redirect: STORAGE_NVSB_ROUTE,
    },
  },
  {
    access: 1,
    path: "/",
    Component: () => <TimetableNew warehouse={1} />,
    protection: {
      condition: user?.storage_access?.["chekhov"],
      redirect: STORAGE_NVSB_ROUTE,
    },
  },
  {
    access: 1,
    path: STORAGE_NVSB_ROUTE,
    Component: () => <TimetableNew warehouse={2} />,
    protection: {
      condition: user?.storage_access?.["nvsb"],
      redirect: STORAGE_KZ_ROUTE,
    },
  },
  {
    access: 1,
    path: STORAGE_KZ_ROUTE,
    Component: () => <TimetableNew warehouse={3} />,
    protection: {
      condition: user?.storage_access?.["kz"],
      redirect: PAGE_NOT_ACCESS,
    },
  },
  // {
  //   path: ATTENDANCE,
  //   Component: Attendance,
  // },
  {
    path: EMPLOYEE_LIST,
    Component: EmployeeList,
  },
  {
    access: 11,
    path: DRIVER_REPORT,
    Component: DriverReport,
  },
  {
    access: 12,
    path: CONTAINER_REPORT,
    Component: ContainerReport,
  },
  {
    access: 9,
    path: ADDITIONAL_REPORT,
    Component: AdditionalReport,
  },
  {
    access: 14,
    path: CANCEL_REPORT,
    Component: CancelReport,
  },
  {
    access: 15,
    path: WEIGHT_REPORT,
    Component: WeightReport,
  },
  {
    access: 0,
    path: LOADING_REGISTRATION,
    Component: LoadingRegistration,
  },
  {
    access: 3,
    path: APPROVE,
    Component: Approve,
  },
  {
    access: 7,
    path: RECORDS_LIST,
    Component: RecordList,
  },
  {
    access: 0,
    path: SHOW_RECORD,
    Component: ShowRecord,
  },
  {
    access: 4,
    path: DRIVER_INFO,
    Component: DriverInfo,
  },
  {
    path: ADMINISTRATION_USERS,
    Component: AdministrationUsers,
    protection: {
      condition: user.type_id === 5 || user.id === 3455 || user.id === 4272,
      redirect: STORAGE_CHEKHOV_ROUTE,
    },
  },
  {
    path: ADMINISTRATION_ACCESS_USERS,
    Component: AdministrationAccessUsers,
    protection: {
      condition: user.type_id === 5 || user.id === 3455 || user.id === 4272,
      redirect: STORAGE_CHEKHOV_ROUTE,
    },
  },
  {
    path: ADMINISTRATION_PRIVILEGED_OPERATIONS,
    Component: PrivilegedOperationsAdministration,
    protection: {
      condition: process.env.REACT_APP_TEST_START === "true",
      redirect: STORAGE_CHEKHOV_ROUTE,
    },
  },
  {
    path: PAGE_NOT_ACCESS,
    Component: PageNotAccess,
  },
];
