import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Spin, Steps } from "antd";
import React, { useState, useEffect, useContext } from "react";

import EventLogItemModal from "./logItem/EventLogItemModal";
import { Context } from "../../../..";
import notificationService from "../../../../services/notificationService";
import { notificationWarehouse } from "../../../../utils/helpers";
import { requestWrapper } from "../../../../utils/requestWrapper";

const EventLogModal = ({ active, setActive, timetable }) => {
  const { user } = useContext(Context);
  const [eventLogItems, setEventLogItems] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isEventLogItemModalVisible, setIsEventLogItemModalVisible] = useState(false);
  const [eventLogItemType, setEventLogItemType] = useState("");
  const [selectedEventLogItem, setSelectedEventLogItem] = useState(null);

  const [getEventLogs] = requestWrapper({
    requestFunction: notificationService.getEventLogs,
    onSuccess: (data) => {
      if (data?.length) {
        setEventLogItems(data);
      } else {
        setEventLogItems([]);
      }
    },
    onError: (message) => {
      notificationWarehouse("error", message);
    },
    setLoading: setLoading,
  });

  const handleCloseEventLogItemModal = (refreshData) => {
    setIsEventLogItemModalVisible(false);
    if (refreshData) {
      setLoading(true);
      setTimeout(() => {
        getEventLogs(timetable.id);
      }, 100);
    }
  };

  useEffect(() => {
    if (active) {
      setLoading(true);
      getEventLogs(timetable.id);
    }
  }, [active]);

  return (
    <Modal
      title="История события"
      open={active}
      onCancel={() => {
        setActive(false);
      }}
      footer={
        user.user.type_id === 5 ? (
          <Row justify="end">
            <Button
              type="primary"
              onClick={() => {
                setIsEventLogItemModalVisible(true);
                setEventLogItemType("create");
              }}
            >
              Добавить новое событие
            </Button>
          </Row>
        ) : (
          <></>
        )
      }
    >
      <Spin spinning={isLoading}>
        <Steps progressDot direction="vertical" current={eventLogItems.length}>
          {eventLogItems.map((item) => (
            <Steps.Step
              key={item.id}
              title={item.title}
              subTitle={item.subTitle}
              description={
                <Row justify="space-between" align="middle">
                  <Col>{item.description}</Col>
                  {user.user.type_id === 5 && (
                    <Col>
                      <Button
                        icon={<EditOutlined />}
                        style={{ marginRight: "5px" }}
                        onClick={() => {
                          setIsEventLogItemModalVisible(true);
                          setEventLogItemType("edit");
                          setSelectedEventLogItem(item);
                        }}
                      />
                      <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          setIsEventLogItemModalVisible(true);
                          setEventLogItemType("delete");
                          setSelectedEventLogItem(item);
                        }}
                      />
                    </Col>
                  )}
                </Row>
              }
            />
          ))}
        </Steps>
        {isEventLogItemModalVisible && (
          <EventLogItemModal
            active={isEventLogItemModalVisible}
            setActive={handleCloseEventLogItemModal}
            eventType={eventLogItemType}
            selectedEventLogItem={selectedEventLogItem}
            setSelectedItem={setSelectedEventLogItem}
            timetable={timetable}
          />
        )}
      </Spin>
    </Modal>
  );
};

export default EventLogModal;
