import { Button, Col, Input, Modal, Row, Spin } from "antd";
import React, { useState } from "react";
import { notificationWarehouse } from "../../utils/helpers";

const ShoppingCart = ({ active, setActive, data, saveShop }) => {
  const [shoppingText, setShoppingText] = useState(data?.shoppingCart || "");

  const close = () => {
    setShoppingText("");
    setActive(false);
  };

  const save = async () => {
    if (data?.id) {
      saveShop({ id: data.id, shoppingCart: shoppingText.trim() });
      close();
    } else {
      notificationWarehouse("warning", "Для шаблонной записи невозвожно добавить название магазина");
    }
  };

  return (
    <Modal
      title={"Введите название магазина"}
      open={active}
      onCancel={() => {
        setActive(false);
      }}
      footer={[
        <Row gutter={[5, 5]} justify={"space-between"} key={"shopping-cart-footer"}>
          <Col>
            <Button danger onClick={close}>
              Отменить
            </Button>
          </Col>
          <Col>
            <Button type="primary" onClick={save}>
              Сохранить
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <p>Введите название магазина:</p>
      <Input
        placeholder="Введите название магазина"
        value={shoppingText}
        onChange={(e) => setShoppingText(e.target.value)}
      />
    </Modal>
  );
};

export default ShoppingCart;
