import { $host } from "../http";

class UserService {
  async getUsersAccess({ page, limit, sortField, sortOrder, fio = "", jobTitle = "", state = "" }) {
    const response = await $host.get("/api/user/all/access", {
      params: {
        page: page,
        limit: limit,
        sortField: sortField,
        sortOrder: sortOrder,
        ...(fio ? { fio: fio } : null),
        ...(jobTitle ? { jobTitle: jobTitle } : null),
        ...(state ? { state: state } : null),
      },
    });

    return response;
  }

  async changeUserAccess(userId, accessStorage) {
    const response = await $host.put("/api/user/access", { userId, accessStorage });

    return response;
  }

  usersByTypeID({ onSuccessful, onError }) {
    return {
      getUsers: (data) => {
        $host
          .post("/api/user/users", data)
          .then(async (response) => {
            if (response.status === 200) {
              return response.data;
            } else {
              throw response.data;
            }
          })
          .then((response) => {
            onSuccessful(response);
          })
          .catch((err) => {
            onError(err.message);
          });
      },
    };
  }

  userById({ onSuccessful, onError }) {
    return {
      getUser: (id) => {
        $host
          .get(`/api/user/${id}`)
          .then((response) => {
            onSuccessful(response.data);
          })
          .catch((err) => {
            onError(err.response?.data?.message);
          });
      },
    };
  }

  async getUser(id) {
    return $host
      .get(`/api/user/${id}`)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err.response?.data?.message);
      });
  }
}

export default new UserService();
